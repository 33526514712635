<style lang="scss">
#sitebarMain {
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
  border-right: 2px solid #d6d6d7;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  width:330px;
  z-index:10;
  .sitebarInner {
    
    width:100%;
    height:100vh;
  }
}
#wrapperMain {
  min-height: 100vh;
  width:100%;
  padding-left:340px;
  .content {
    margin-top: 80px;
    padding-bottom: 50px;
  }

  #header {
    margin-bottom: 100px;
  }

  &.grey {
    background-color: #e9edf2;
  }

  &.nopad {
    padding: 0 !important;
  }
}
</style>
<template>
  <div class="container-fluid">
    <div class="row">
      <div id="sitebarMain">
        <div class="sitebarInner">
        <Navigation />
        </div>
      </div>
      <div id="wrapperMain" :class="{ grey: isGrey, nopad: noPad }">
        <Header />
        <router-view class="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from 'vue';
import Navigation from '../../components/Navigation.vue';
import Header from '../../components/Header.vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    Navigation,
    Header,
  },
  setup() {
    const isGrey = ref(false);
    const noPad = ref(false);
    provide('setGrey', () => {
      isGrey.value = true;
    });

    provide('setNoPad', () => {
      noPad.value = true;
    });

    return { isGrey, noPad };
  },
  // setup() {
  //   const store = useStore();
  //   const menustate = computed(() => store.state.menustate);
  //   return {
  //     menustate,
  //     breakpoint: inject('breakpoint'),
  //   };
  // },
});
</script>
