
import { defineComponent, provide, ref } from 'vue';
import Navigation from '../../components/Navigation.vue';
import Header from '../../components/Header.vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    Navigation,
    Header,
  },
  setup() {
    const isGrey = ref(false);
    const noPad = ref(false);
    provide('setGrey', () => {
      isGrey.value = true;
    });

    provide('setNoPad', () => {
      noPad.value = true;
    });

    return { isGrey, noPad };
  },
  // setup() {
  //   const store = useStore();
  //   const menustate = computed(() => store.state.menustate);
  //   return {
  //     menustate,
  //     breakpoint: inject('breakpoint'),
  //   };
  // },
});
