<style lang="scss" scoped>
.accordion-container {
  .nav-entry {
    padding: 10px 10px 10px 30px !important;
  }
}

.accordion {
  .mdi-menu-right {
    transition: all 0.1s ease;
    margin-right: 5px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -10px;
    transform: rotate(0deg);
    border-radius: 0;
    transform-origin: top;
    border-radius: 0;
    transform-origin: 50% 50%;
  }
}

.openAccordion {
  .mdi-menu-right {
    transform: rotate(90deg) !important;
  }
}

.title {
  position: relative;
  cursor: pointer;
}

.accordion-enter-active,
.accordion-leave-active {
  transition: all 0.3s ease;
}

.accordion-enter-from,
.accordion-leave-to {
  max-height: 0vh;
  opacity: 0;
}

.accordion-enter-to,
.accordion-leave-from {
  max-height: 100vh;
}
</style>

<template>
  <div :class="['accordion', { openAccordion: isOpen }]">
    <div @click="toggle" :class="titleclass" class="title">
      <div class="nav-entry">
        <span>
          <w-icon v-if="icon" class="menu-icon">mdi mdi-{{ icon }}</w-icon>
          {{ title }}</span
        >
        <Icon height="20" width="20" class="mdi-menu-right" :path="mdiMenuRight" />
      </div>
    </div>
    <transition name="accordion">
      <div v-if="isOpen" class="accordion-container pl3">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

import { mdiMenuRight } from '@mdi/js';

export default defineComponent({
  props: {
    title: { type: String, required: true },
    open: Boolean,
    titleclass: String,
    icon: String,
  },
  setup(props) {
    const checkSessionStorage = () => {
      let storage;
      try {
        storage = window['sessionStorage'];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return e && (e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') && storage && storage.length !== 0;
      }
    };

    const isOpen = ref(false);
    if (checkSessionStorage()) {
      if (sessionStorage.getItem(props.title) !== null) {
        isOpen.value = sessionStorage.getItem(props.title) === 'true';
      }
    }

    const toggle = () => {
      isOpen.value = !isOpen.value;
      if (checkSessionStorage()) {
        sessionStorage.setItem(props.title, isOpen.value ? 'true' : 'false');
      }
    };

    return {
      isOpen,
      toggle,
      mdiMenuRight,
    };
  },
});
</script>
