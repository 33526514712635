
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import Accordion from './Accordion.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Accordion,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const role = store.getters['getUserRole'];
    const user = store.state.currentUser;
    const name = store.getters['getFullName'];
    //const avatar = store.getters['getAvatar'];
    const avatar = computed(() => store.getters['getAvatarUrl'](store.state.currentUser.userAvatar));
    const onLogout = () => {
      store.dispatch('logout').then(() => {
        router.push('/');
      });
    };
    const toggleMenu = () => {
      store.dispatch('setMenuState', !store.state.menustate);
    };
    return {
      onLogout,
      role,
      name,
      avatar,
      toggleMenu,
    };
  },
});
