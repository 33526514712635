<style lang="scss">
#nav {
  //position: fixed;
  padding-top: 40px;
  // #nav-fixed {
  //   width: 350px;
  //   @media (max-width: 400px) {
  //     width: 100%;
  //     max-width: 100%;
  //   }
  //   @media (max-height: 500px) {
  //     width: 330px;
  //   }
  // }

  .nameframe {
    text-align: center;

    .picture {
      margin: 0 auto;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      // img {
      //   width: 100%;
      //   height: auto;
      // }
    }

    .name {
      font-family: 'HelveticaNeueLTPro-Lt';
      margin-top: 20px;
      //font-weight: bold;
      font-size: 1.5rem;
    }
  }

  nav {
    margin-top: 50px;
    .nav-entry {
      $leftpadding: 100px;

      position: relative;
      font-family: 'HelveticaNeueLTPro-Lt';
      display: block;
      text-decoration: none;
      color: #000;
      padding: 20px;
      padding-left: $leftpadding;
      border: 1px solid transparent;
     
      &.router-link-active {
        background-color: #e8ecf1;
        //font-weight: bold;
        font-family: 'HelveticaNeueLTPro-Hv';

        &::before {
          display: block;
          position: absolute;
          content: '';
          background-color: #000;
          border-radius: 50%;
          height: 3px;
          width: 3px;

          top: 50%;
          left: $leftpadding - 10px;
          transform: translateY(-50%);
        }
      }

      &:hover {
        background-color: #e8ecf1;
      
      }
    }
     .divider {
        width:70%;
        margin:10px auto;
        border-top:1px dashed #e0e3eb;
     
       
      }
  }
}
</style>
<template>
  <div id="nav">
    <div class="nameframe">
      <div class="picture" :style="`background-image: url(${avatar})`"></div>
      <div class="name">
        {{ name }}
      </div>
    </div>

    <nav>
     
      <router-link :to="{ name: 'Overview' }" @click="toggleMenu" class="nav-entry">Überblick</router-link>
      <router-link :to="{ name: 'MyProfile' }" @click="toggleMenu" class="nav-entry">Meine Skills</router-link>
     
      <router-link :to="{ name: 'Analyse' }" @click="toggleMenu" class="nav-entry" v-if="role >= 20">Analyse</router-link>
      <router-link :to="{ name: 'Mitarbeiter' }" @click="toggleMenu" class="nav-entry" v-if="role >= 20">Mitarbeiter</router-link>
      <router-link :to="{ name: 'Skills' }" @click="toggleMenu" class="nav-entry" v-if="role >= 20"> Skill suchen</router-link>
      <router-link :to="{ name: 'TrainingsIndex' }" @click="toggleMenu" class="nav-entry">Trainings</router-link>
      <div class="divider"></div>
      <router-link :to="{ name: 'Mitarbeiter verwalten' }" @click="toggleMenu" class="nav-entry" v-if="role >= 20"> Mitarbeiter verwalten</router-link>
      <router-link :to="{ name: 'Password Ändern' }" class="nav-entry">Mein Passwort ändern</router-link>
      <a href="#" class="nav-entry" @click="onLogout">Ausloggen</a>
      <!-- <Accordion title="Verwaltung" v-if="role >= 20">
        
        <router-link :to="{ name: 'Login' }" @click="toggleMenu" class="nav-entry" v-if="role >= 50"><s>Skills</s></router-link>
        <router-link :to="{ name: 'Login' }" @click="toggleMenu" class="nav-entry" v-if="role >= 50"><s>Umfragen</s></router-link>
      </Accordion> -->
    </nav>

    <!-- <div id="nav-fixed">
      <div class="nav-inner">
        <div class="close-sitebar" @click="toggleMenu">
          <w-icon class="menu-icon">mdi mdi-close</w-icon>
        </div>
        <div class="logo">
          <router-link :to="{ name: 'Overview' }"><img :src="`${require('@/assets/smd-neu-1c.svg')}`" alt="SMD - Logo"/></router-link>
        </div>
        <w-divider class="nav-divider" />
         PROFIL
        <Accordion :title="name" icon="account-circle">
          
          
          
        </Accordion>
        <w-divider class="nav-divider" />
        PROFIL ENDE
        <router-link :to="{ name: 'Overview' }" @click="toggleMenu" class="nav-entry">Überblick</router-link>
        <router-link :to="{ name: 'Skills' }" @click="toggleMenu" class="nav-entry"> Skill suchen</router-link>
        <router-link :to="{ name: 'Analyse' }" @click="toggleMenu" class="nav-entry" v-if="role >= 20">Analyse</router-link>
        <router-link :to="{ name: 'Mitarbeiter' }" @click="toggleMenu" class="nav-entry" v-if="role >= 20">Mitarbeiter</router-link>
        <w-divider v-if="role >= 20" class="nav-divider-30" />
        
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import Accordion from './Accordion.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Accordion,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const role = store.getters['getUserRole'];
    const user = store.state.currentUser;
    const name = store.getters['getFullName'];
    //const avatar = store.getters['getAvatar'];
    const avatar = computed(() => store.getters['getAvatarUrl'](store.state.currentUser.userAvatar));
    const onLogout = () => {
      store.dispatch('logout').then(() => {
        router.push('/');
      });
    };
    const toggleMenu = () => {
      store.dispatch('setMenuState', !store.state.menustate);
    };
    return {
      onLogout,
      role,
      name,
      avatar,
      toggleMenu,
    };
  },
});
</script>
