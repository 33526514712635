<style lang="scss">
#header {
  position: relative;
  padding-top: 4vh;
  .numbers {
    text-align: center;
    color: #9a9c9e;

    .number {
      font-weight: bold;
    }
  }
  .logo {
    position: absolute;
    right: 1vw;
    width:200px;
    top:30px;
  }
}
</style>

<template>
  <div id="header">
    <div class="logo"><img src="@/assets/smd-logo.svg" /></div>
    <div class="row numbers">
      <div class="col-12 ">
        <span class="number">{{ meta.skills }}</span> Skills | <span class="number">{{ meta.mitarbeiter }}</span> Mitarbeiter*innen
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const meta = ref({ teams: 0, mitarbeiter: 0, skills: 0 });

    store.dispatch('fetchMeta').then((res) => {
      meta.value = res;
    });

    return {
      meta,
    };
  },
});
</script>
