
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const meta = ref({ teams: 0, mitarbeiter: 0, skills: 0 });

    store.dispatch('fetchMeta').then((res) => {
      meta.value = res;
    });

    return {
      meta,
    };
  },
});
